import React from 'react'
import i18next from './src/locale/i18next'
import { I18nextProvider } from 'react-i18next'
import { PageContextProvider } from './src/context/page-context'

export const wrapRootElement = ({ element }) => {
  return <I18nextProvider i18n={i18next}>{element}</I18nextProvider>
}

export const wrapPageElement = ({ element, props }) => {
  return (
    <PageContextProvider pageContext={props.pageContext}>
      {element}
    </PageContextProvider>
  )
}

export function onRenderBody({ pathname, setHeadComponents }) {
  let scripts = [
    <script
      key="usercentrics-cmp"
      id="usercentrics-cmp"
      src="https://app.usercentrics.eu/browser-ui/latest/loader.js"
      data-settings-id="P1bjZsOAj"
      async
    />,
    <script
      type="text/javascript"
      key="plugin-google-tagmanager-consent"
      id="plugin-google-tagmanager-consent"
      dangerouslySetInnerHTML={{
        __html: `
        window.dataLayer = window.dataLayer || [];
        function gtag() {dataLayer.push(arguments);}
        gtag("consent", "default", {
            ad_user_data: "denied",
            ad_personalization: "denied",
            ad_storage: "denied",
            analytics_storage: "denied",
            wait_for_update: 2000
        });`,
      }}
    />,
    <script
      type="text/javascript"
      key="plugin-google-tagmanager"
      id="plugin-google-tagmanager"
      dangerouslySetInnerHTML={{
        __html: `
        (function(w, d, s, l, i) {
              w[l] = w[l] || [];
              w[l].push({
                  'gtm.start': new Date().getTime(),
                  event: 'gtm.js'
              });
              var f = d.getElementsByTagName(s)[0],
                  j = d.createElement(s),
                  dl = l != 'dataLayer' ? '&l=' + l : '';
              j.async = true;
              j.src =
                  'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
              f.parentNode.insertBefore(j, f);
          })(window, document, 'script', 'dataLayer', 'GTM-W3P65L8L');
        `,
      }}
    />,
  ]

  const isApp =
    pathname.includes('/app-terms') || pathname.includes('/app-privacy-policy')

  if (isApp) {
    scripts = []
  }

  setHeadComponents(scripts)
}
